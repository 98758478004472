import type { Slider } from '~/types'
import NextImage from 'next/image';
import { BackgroundWrap, ButtonWithArrow, Cursive, GradientWrap, Wrapper } from '~/ui'
import { ButtonForCarousel } from '~/ui/button-for-carousel'

interface SliderCardProps {
  sliderData: Slider
  isHeroSlider?: boolean
  isActiveSlide?: boolean
  image: string
}

export const SliderCard = ({ sliderData, isHeroSlider, isActiveSlide, image }: SliderCardProps) => {
  const { id, title, subtitle, buttonLink, buttonText } = sliderData
  return (
    <BackgroundWrap
      className={`${isHeroSlider ? 'embla__slide__hero' : 'embla__slide'} 
      ${isActiveSlide ? 'is-active-slide' : ''} 
      sliderhome relative h-full w-full bg-colorSixteen bg-contain !bg-right bg-no-repeat`}
    >
      <div className="absolute right-0 hidden h-full lg:block">
        <div className="absolute left-0 zIndex-9 h-full w-1/3 bg-gradient-to-r  from-colorSixteen via-colorSixteen/50 to-transparent" />
        <NextImage
          className="h-full"
          src={`${image}`}
          width={875}
          height={750}
          loading="lazy"
          placeholder="blur"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8+/btfwAJZAO2zFgDiwAAAABJRU5ErkJggg==" alt={''} />
      </div>
      <div
        className="absolute right-0 h-full w-full bg-cover !bg-center bg-no-repeat lg:hidden"
        style={{
          backgroundImage: `url("${image}")`
        }}
      >
        <div className="h-full w-full bg-gradient-to-r  from-colorSixteen via-colorSixteen/50 to-transparent" />
      </div>
      <GradientWrap className="absolute zIndex-99 flex h-full justify-start w-[75%] md:w-[70%] md:via-colorSixteen lg:w-[70%] lg:via-colorSixteen lg:!py-0">
        <div className="m-10 flex w-[80%] flex-col text-left md:m-24 lg:m-32 lg:w-[50%]">
          <p className="font-tucker-tub text-title font-bold uppercase text-colorFifteen lg:text-sliderTitle">
            {title}
          </p>
          <Cursive className="text-sliderTitle  text-colorEight md:text-header lg:text-bigHeader">
            {subtitle}
          </Cursive>
          <div className="h-full w-full py-8 lg:py-10">
            <ButtonForCarousel
              href={buttonLink}
              className="w-full xs:w-[280px] bg-colorFourteen hover:bg-colorTen lg:w-[323px]"
            >
              {buttonText}
            </ButtonForCarousel>
          </div>
        </div>
      </GradientWrap>
    </BackgroundWrap>
  )
}