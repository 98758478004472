import type { Slider } from '~/types'

export const homepageSliderData: Slider[] = [
  {
    id: 1,
    title: 'Feed your dog with love',
    subtitle: 'All natural dog food, made farm fresh in Australia.',
    buttonText: 'Shop Natural Dog Food',
    buttonLink: '/shop',
    image: '/assets/images/slider/Tucker-Tub_Feed-Your-Dog-With-Love.jpg'
  },
  {
    id: 2,
    title: 'Your dog is unique',
    subtitle: 'Our pet nutritionists create a meal plan just for your dog.',
    buttonText: 'Book a Consultation',
    buttonLink: '/consultation',
    image: '/assets/images/slider/Tucker-Tub_Your-Dog-Is-Unique.jpg'
  },
  {
    id: 3,
    title: 'We know dogs',
    subtitle: "We've been making all natural dog food for over 25 years.",
    buttonText: 'Read Our Story',
    buttonLink: '/our-story',
    image: '/assets/images/slider/Tucker-Tub_We-Know-Dogs.jpg'
  }
]
