import { NextSeo } from 'next-seo'
import dynamic from 'next/dynamic'

import { Layout } from '~/components/common'
import { EmblaCarousel } from '~/components/common/carousel'
import { homepageSliderData } from '~/components/common/carousel/data/home-slider-data'
import { wooCommerceAPI } from '~/lib/WooCommerce'
import type { ArticleProps, Product } from '~/types'
import type { YoastHeadJson } from '~/types/WooCommerce/yoast'

interface HomeProps {
  products: Product[]
  articles: ArticleProps
  seo: YoastHeadJson | null
}

const DynamicBlockPersonalised = dynamic(
  async () => {
    const component = await import('~/components/common/homepage/block_lottiedog')
    return component.BlockPersonalised
  },
  { ssr: false }
)

const DynamicBlockProducts = dynamic(
  async () => {
    const component = await import('~/components/common/homepage/block_products')
    return component.BlockProducts
  },
  { ssr: false }
)

const DynamicBlockTips = dynamic(async () => {
  const component = await import('~/components/common/homepage/block_tips')
  return component.BlockTips
})

const DynamicBlockDifference = dynamic(async () => {
  const component = await import('~/components/common/homepage/block_difference')
  return component.BlockDifference
})

export default function Home({ products, articles, seo }: HomeProps) {
  return (
    <>
      <NextSeo
        title={seo?.title}
        description={seo?.description}
        openGraph={{
          title: seo?.og_title,
          description: seo?.og_description,
          url: process.env.NEXT_PUBLIC_FRONTEND_URL,
          type: seo?.og_type,
          locale: seo?.og_locale,
          site_name: seo?.og_site_name,
          images: seo?.og_image
        }}
      />
      <Layout>
        <EmblaCarousel sliderData={homepageSliderData} />
        <DynamicBlockProducts products={products} />
        <DynamicBlockPersonalised />
        <DynamicBlockDifference />
        <DynamicBlockTips articles={articles} />
      </Layout>
    </>
  )
}

export async function getStaticProps() {
  const endpoint = process.env.WORDPRESS_ENDPOINT

  const homePageResponse = await fetch(`${endpoint}/wp-json/wp/v2/pages?slug=home`)

  const { data } = await wooCommerceAPI.get('products?status=publish&per_page=100')

  const response = await fetch(
    `${endpoint}/wp-json/wp/v2/posts?categories=${process.env.WORDPRESS_CATEGORY_NUTRITION}&status=publish`
  )
  const articlesData = await response.json()

  const homePageData = await homePageResponse.json()

  const featuredMedia = articlesData.map(post => post.featured_media)

  const featuredMediaResponse = await Promise.all(
    featuredMedia.map(async id => {
      const response = await fetch(`${endpoint}/wp-json/wp/v2/media/${id}`)
      return response.json()
    })
  )

  const filtermediaWithImage = featuredMediaResponse.filter(media =>
    articlesData.map(post => post.featured_media).includes(media.id)
  )

  const combinedData = articlesData.map(post => ({
    ...post,
    featuredMedia:
      filtermediaWithImage.find(media => media.id === post.featured_media)?.source_url ?? ''
  }))

  return {
    props: {
      products: data,
      articles: combinedData,
      seo: homePageData.length > 0 ? homePageData[0].yoast_head_json : null
    }
  }
}
